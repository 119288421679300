import { COLLECTION, INSTRUCTION, SCAN_STATUS, DEMO_LENS } from "../constants"
import {
  getDb,
  getStorage,
  getScanRefs,
  associateScanWithRefs,
} from "./helpers"

const { ORGANIZATIONS, SCANNERS, SCANS, LENSES } = COLLECTION

const scans = {
  startScanning: async (
    orgId,
    scannerId,
    lens,
    user,
    scanID,
    calibrationScan = false
  ) => {
    const db = await getDb()
    let scanner_doc_content = {
      user_scan_params: {
        lens: { ...lens, supported: true },
        scan_id: scanID,
        user_id: user.uid,
        org_id: orgId,
        calibration: calibrationScan,
      },
      instruction: INSTRUCTION.START,
      author_email: user.email,
      percent_complete: 0,
    }
    // It's somehow possible for us to have some spurious `undefined`
    // keys in our lens object. Remove those.
    let obj = scanner_doc_content.user_scan_params.lens
    Object.keys(obj).forEach((key) =>
      obj[key] === undefined ? delete obj[key] : {}
    )
    return await db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(SCANNERS)
      .doc(scannerId)
      .update(scanner_doc_content)
  },
  startDemo: async (orgId, scannerId, author, scanID) => {
    const db = await getDb()
    return await db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(SCANNERS)
      .doc(scannerId)
      .update({
        user_scan_params: {
          lens: DEMO_LENS,
          quality: 7,
          save_data: false,
          org_id: orgId,
          scan_id: scanID,
        },
        instruction: INSTRUCTION.START_DEMO,
        author_email: author,
        percent_complete: 0,
        scan_id: scanID,
      })
  },
  stopScanning: async (orgId, scannerId) => {
    const db = await getDb()
    return await db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(SCANNERS)
      .doc(scannerId)
      .update({
        instruction: INSTRUCTION.STOP,
        current_scan: "stopping",
      })
  },

  getExternalImages: async (orgId, scanId) => {
    const db = await getDb()
    const cloudStorage = await getStorage()
    const scan = await db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(SCANS)
      .doc(scanId)
      .get()
    const scanData = scan.data()
    const resultsPath = scanData.results_path
    const resultsRef = cloudStorage.ref(resultsPath)
    const externalImagesFolder = resultsRef.child("ExternalImages")
    const externalImagePromises = []
    await externalImagesFolder.listAll().then((result) => {
      return result.items.forEach((file) => {
        externalImagePromises.push(file.getDownloadURL())
      })
    })
    return Promise.all(externalImagePromises)
  },

  getScansByLens: async (orgId, lensId) => {
    const db = await getDb()
    const lensRef = db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(LENSES)
      .doc(lensId)

    const scansSnapshot = await db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(SCANS)
      .where("lens_ref", "==", lensRef)
      .where("status", "in", [
        SCAN_STATUS.PROCESSED,
        SCAN_STATUS.PARTIALLY_PROCESSED,
        SCAN_STATUS.READY_TO_REVIEW,
      ])
      .get()

    let scans = scansSnapshot.docs.map((doc) => doc.data())
    scans = scans.filter((s) => s.surfaces !== undefined && s.hidden !== true)
    const cloudStorage = await getStorage()

    let surfaceImagePromises = []
    for (let scan of scans) {
      // Get Cloud Storage URLs for surfaces.
      for (let surface of Object.values(scan.surfaces)) {
        if (surface.image !== undefined) {
          // Generate Cloud Storage URL from image path.
          let gsReference = cloudStorage.ref(surface.image)
          surfaceImagePromises.push(gsReference.getDownloadURL())
        } else {
          // No image (still_processing).
          surfaceImagePromises.push(undefined)
        }
      }
    }

    // Generate access tokens for images, create URLs with tokens.
    let surface_images = await Promise.all(
      surfaceImagePromises.map((gs) =>
        gs ? gs.catch(() => undefined) : undefined
      )
    )

    for (let scan of scans) {
      let surfaces = Object.values(scan.surfaces)
      for (let i = 0; i < surfaces.length; i++) {
        // Associate returned URL with correct surface.
        surfaces[i].image_url = surface_images.shift()
      }
    }
    return scans.sort(
      (scanA, scanB) => scanB.timestamp.seconds - scanA.timestamp.seconds
    )
  },
  getRecentScanByLens: async (orgId, lensId) => {
    const db = await getDb()
    const lensRef = db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(LENSES)
      .doc(lensId)

    const scansSnapshot = await db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(SCANS)
      .orderBy("timestamp", "desc")
      .where("lens_ref", "==", lensRef)
      .where("status", "in", [
        SCAN_STATUS.PROCESSED,
        SCAN_STATUS.PARTIALLY_PROCESSED,
        SCAN_STATUS.READY_TO_REVIEW,
      ])
      .limit(1)
      .get()

    let scans = scansSnapshot.docs.map((doc) => doc.data())
    scans = scans.filter((s) => s.surfaces !== undefined && s.hidden !== true)
    let scan = scans[0] || {}

    const lens = await lensRef.get()
    scan.lens = { id: lens.id, ...lens.data() }

    const lensType = await scan.lens.lens_type_ref.get()
    scan.lensType = lensType.data()

    if (scan.surfaces === undefined) return scan

    const cloudStorage = await getStorage()

    let image_promises = []

    // Get Cloud Storage URLs.
    for (let surface of Object.values(scan.surfaces)) {
      if (surface.image !== undefined) {
        // Generate Cloud Storage URL from image path.
        let gsReference = cloudStorage.ref(surface.image)
        image_promises.push(gsReference.getDownloadURL())
      } else {
        // No image (still_processing).
        image_promises.push(undefined)
      }
    }

    // Generate access tokens for images, create URLs with tokens.
    let surface_images = await Promise.all(
      image_promises.map((gs) => (gs ? gs.catch(() => undefined) : undefined))
    )

    let surfaces = Object.values(scan.surfaces)
    for (let i = 0; i < surfaces.length; i++) {
      // Associate returned URL with correct surface.
      surfaces[i].image_url = surface_images.shift()
    }
    return scan
  },
  onScanStatusChange: async (orgId, scanId, onStatusChange) => {
    const db = await getDb()
    return await db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(SCANS)
      .doc(scanId)
      .onSnapshot((snapshot) =>
        onStatusChange({ ...snapshot.data(), id: snapshot.id })
      )
  },
  onScansChange: async (orgId, onAdded, onModified, onDone) => {
    const db = await getDb()
    return db
      .collection(ORGANIZATIONS)
      .doc(orgId)
      .collection(SCANS)
      .orderBy("timestamp", "asc")
      .onSnapshot(async (snapshot) => {
        let addedScans = []
        let addedScanRefs = []
        const changes = snapshot.docChanges()

        for (var i = 0; i < changes.length; i++) {
          const change = changes[i]
          let scan = change.doc.data()
          if (
            scan.lens_ref === undefined ||
            scan.lens_ref === null ||
            scan.lens_type_ref === undefined ||
            scan.lens_type_ref === null ||
            scan.user_ref === undefined ||
            scan.user_ref === null ||
            scan.scanner_ref === undefined ||
            scan.scanner_ref === null ||
            scan._id.startsWith("test_") ||
            scan.hidden === true
          ) {
            continue
          }
          if (change.type === "added") {
            addedScans.push(scan)
            addedScanRefs.push(getScanRefs(scan))
          }
          if (change.type === "modified") {
            const refs = await getScanRefs(scan)
            scan = associateScanWithRefs(scan, refs)
            onModified(scan)
          }
          // Not listening for change.type === "deleted" as there's currently no UI
          // mechanism for users to delete scans.
        }
        addedScanRefs = await Promise.all(addedScanRefs)

        while (addedScans.length > 0) {
          let scan = addedScans.shift()
          let refs = addedScanRefs.shift()
          scan = associateScanWithRefs(scan, refs)
          onAdded(scan)
        }
        onDone()
      })
  },
}

export default scans
