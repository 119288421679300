import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Card, Tooltip, Space } from "antd"
import {
  QuestionCircleOutlined,
  FullscreenOutlined,
  StopOutlined,
} from "@ant-design/icons"
import css from "./index.module.css"
import cx from "classnames"
import { generateTimeString } from "../../../helpers/date"
import { SCAN_STATUS } from "../../../state/constants"
import { useGlobalState } from "../../../state"
import LensModal from "../LensModal"
import { pushScanToHistory, resetHistory } from "../../../helpers/url"

export default function ScanCard({ scan, mapStatusToVisualInfo }) {
  const [state, dispatch] = useGlobalState()
  const [, setLoading] = useState(true)
  const [lens, setLens] = useState({})
  const [scanner, setScanner] = useState({})
  const [viewLinkDisabled, setViewLinkDisabled] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)
  const TOUR_FIRST_STEP = scan._id === "scan_2020-09-22_16-21-54"

  useEffect(() => {
    if (!scan) return
    setLoading(false)
    const lensObject = { lens_id: scan.lens_ref.id, ...scan.lens }
    setLens(lensObject)
    setScanner(scan.scanner)
    // Automatically release scans upon algorithm completion.
    const autoRelease = [
      SCAN_STATUS.PROCESSED,
      SCAN_STATUS.PARTIALLY_PROCESSED,
      SCAN_STATUS.READY_TO_REVIEW,
    ].includes(scan.status)

    if (autoRelease) {
      setViewLinkDisabled(false)
    } else {
      setViewLinkDisabled(
        scan.surfaces === undefined ||
          ![
            SCAN_STATUS.PROCESSED,
            SCAN_STATUS.PARTIALLY_PROCESSED,
            SCAN_STATUS.READY_TO_REVIEW,
          ].includes(scan.status)
      )
    }

    const params = new URLSearchParams(window.location.search)
    if (
      params.get("lens") === scan.lens_ref.id &&
      parseInt(params.get("date_a")) === scan.timestamp.seconds
    ) {
      dispatch({
        type: "SET_INVENTORY_LENS",
        lens: lensObject,
      })
      setModalVisible(true)
    }
  }, [scan])

  const handleInventoryClick = (e) => {
    e.preventDefault()
    setModalVisible(true)
    dispatch({
      type: "SET_INVENTORY_LENS",
      lens: lens,
    })
    const surface = Object.keys(scan.surfaces).sort((x, y) => {
      if (scan.surfaces[x].evalue < scan.surfaces[y].evalue) {
        return -1
      }
      if (scan.surfaces[x].evalue > scan.surfaces[y].evalue) {
        return 1
      }
      return 0
    })[0]
    pushScanToHistory(scan, surface)
  }

  const handleCancel = () => {
    resetHistory()
    setModalVisible(false)
  }

  const viewReport = (
    <div
      className={css.viewReport}
      id={TOUR_FIRST_STEP ? "tour-step-0-trigger" : null}
    >
      {viewLinkDisabled ? (
        <Tooltip placement="right" title="This scan is not viewable.">
          <StopOutlined />
        </Tooltip>
      ) : (
        <span>
          <FullscreenOutlined />
        </span>
      )}
    </div>
  )

  const cardHead = {
    padding: "0px 16px",
    margin: 0,
    borderBottom: "none",
  }

  const cardBody = {
    display: "inline",
    padding: "0px 4px",
  }

  const scanVisualInfo = mapStatusToVisualInfo(scan.status)

  const tooltip = (
    <Tooltip placement="top" title={scanVisualInfo.explanation}>
      <QuestionCircleOutlined />
    </Tooltip>
  )

  const modelDetails = (
    <span>
      {scan.lens_model || lens.model} {lens.focal_length_description}mm
    </span>
  )

  const userDetails = (
    <span className={css.emphasis}>
      {scan.user.displayName ? scan.user.displayName : scan.user.email}
    </span>
  )

  const scannerDetails = (
    <span className={css.emphasis}>{scanner.human_name}</span>
  )

  const scanStatus = (
    <div>
      <span className={cx(css.emphasis, css.spaceAfter)}>
        {scanVisualInfo.friendlyStatus}
      </span>
      {tooltip}
    </div>
  )

  return (
    <div>
      <LensModal
        lensId={scan.lens_id}
        visible={modalVisible}
        onCancel={handleCancel}
        lens={{ ...lens, serial_number: lens.serialNumber }}
        tab="scans"
      />
      <Card
        bordered={false}
        className={cx(css.card, "scanCard", {
          disabled: viewLinkDisabled,
        })}
        bodyStyle={cardBody}
        headStyle={cardHead}
        onClick={viewLinkDisabled ? () => {} : handleInventoryClick}
        title={
          <div className={css.titleStyle}>
            <h3>{scan.lens_make || lens.make}</h3>
            {modelDetails}
            <h5>{lens.serialNumber}</h5>
          </div>
        }
        id={TOUR_FIRST_STEP ? "tour-step-0" : null}
        hoverable={!viewLinkDisabled}
      >
        <div className={css.cardImage}>
          {scan.externalImageLink === null ? (
            <img
              className={css.placeholderImage}
              src="camera-lens.png"
              alt="placeholder diagram of camera lens"
            />
          ) : (
            <img
              className={css.externalImage}
              src={scan.externalImageLink}
              alt={`external housing of lens ${lens.serialNumber}`}
            />
          )}
        </div>

        <Space direction="vertical" className={css.cardText}>
          <div className={css.scannedBy}>
            Scanned by
            <br />
            {userDetails}
          </div>
          <div>
            {state.scanners.length >= 2 && (
              <div>
                Scanner
                <br />
                {scannerDetails}
              </div>
            )}
          </div>
          <div className={css.scannerStatus}>
            Status
            <br />
            {scanStatus}
          </div>
        </Space>
        <div className={css.date}>
          {generateTimeString(scan.timestamp.seconds)}
        </div>
        {viewReport}
        <div className={css.statusIcon}>{scanVisualInfo.icon}</div>
      </Card>
    </div>
  )
}

ScanCard.propTypes = {
  scan: PropTypes.object,
  mapStatusToVisualInfo: PropTypes.func,
  index: PropTypes.number,
}
