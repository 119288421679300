export const defaultState = {
  lensTypes: [],
  currentScanLens: null,
  userOrgIds: [],
  userOrgId: undefined,
  scanners: [],
  demoMode: false,
  collapsed: true,
  BETA_USER: false,
  loaded: true,
  shareModalVisible: false,
  calibrationScan: false,
  step: 0,
  addLensModalVisible: false,
  sandbox: false,
  invitedUser: false,
}
