import React from "react"
import PropTypes from "prop-types"
import { Select } from "antd"
import css from "./ScanHistoryFilter.module.css"

export default function ScanHistoryFilter({ ...props }) {
  const { Option } = Select
  const { options, onFilterChange, placeholder } = props
  return (
    <Select
      onChange={onFilterChange}
      placeholder={placeholder}
      allowClear={true}
      className={css.scanFilter}
      dropdownMatchSelectWidth={false}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.title}
        </Option>
      ))}
    </Select>
  )
}

ScanHistoryFilter.propTypes = {
  options: PropTypes.array,
  onFilterChange: PropTypes.func,
  placeholder: PropTypes.string,
}
